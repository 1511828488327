import React from 'react';
import Footer from './Footer';
import './CssStyles/ExecutiveProfile.css';

function ExecutiveProfile() {
    return (
        <div className='executive-profile'>
            <h1 className='exec-title'>Executive Profile</h1>
            <div className='profile-container'>
                <div className='left-section'>
                    <div className='card'>
                        <img src='/Images/ExecutiveProfileImages/jitendra.jpg' alt='Executive' className='profile-image' />
                        <div className='card-info'>
                            <h2>Jitendra Dewoolkar</h2>
                            <p>Founder, Ellora Group / Gagangiri Group</p>
                        </div>
                    </div>
                </div>
                <div className='right-section'>
                    <p className='profile-info'>
                        With over 30 years of extensive experience in Mumbai's real estate sector, Mr.Jitendra Dewoolkar is one of the visionary founder of Ellora Group. Under his leadership, Ellora Group has grown from a consultancy firm into a full-fledged residential / commercial redevelopment company, having a staff of over 70 members with an all-round expertise of Architecture, Engineering, PMC, Legal and more, and having its builder profile  projects under the name Gagangiri Group.
                    </p>
                    <p className='profile-info'>
                        Mr.Jitendra’s deep understanding of the complexities of real estate business, combined with his hands-on approach, has led to the successful execution of numerous projects across Mumbai. His expertise in navigating the ever-changing landscape of Mumbai’s urban re-development, combines with his commitment to quality and ethics. In addition to his contributions to the real estate sector, Mr.Jitendra is also dedicated to the field of education. As the founding trustee of Pragat Shikshan Sanstha.
                    </p>
                    <p className='profile-info'>
                        Thanks to his visionary leadership, Mr.Jitendra Dewoolkar, Billabong High International School, Andheri (W) has achieved many eminent awards like ET INDUSTRY LEADERS 2020, TIMES EDUCATION ICONS 2020, and many more, for being among the top achieving schools in the country.
                    </p>
                </div>
            </div>
            <div className='profile-container-2'>
                <div className='left-section-2'>
                    <p className='profile-info'>
                        With over 25 years of experience in the real estate at Ajantha Group, Mr.Rajendra Dewoolkar is a seasoned residential developer and a vital part of Gagangiri Group. Mr.Rajendra has been instrumental in shaping the company’s growth and success. His deep expertise in managing and executing redevelopment projects across Mumbai and beyond has been key to the firm's reputation for delivering high-quality projects with timely execution to enhance urban living.
                    </p>
                    <p className='profile-info'>
                        Mr.Rajendra’s hands-on experience spans numerous residential redevelopment projects, where his knowledge of the local market and understanding of urban development challenges have helped him navigate complex projects with ease. His keen attention to detail, combined with a commitment to excellence, has made him a trusted leader within Gagangiri Group.
                    </p>
                    <p className='profile-info'>
                        In addition to his work in real estate, Mr.Rajendra is also a founding trustee of Pragat Siksha Sanstha, an organization dedicated to providing quality education through the establishment of schools.
                    </p>
                </div>
                <div className='right-section-2'>
                    <div className='card'>
                        <img src='/Images/ExecutiveProfileImages/RajendraDewoolkar.jpeg' alt='Executive' className='profile-image' />
                        <div className='card-info'>
                            <h2>Rajendra Dewoolkar</h2>
                            <p>Founder, Ajantha Group / Gagangiri Group</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='profile-container'>
                <div className='left-section'>
                    <div className='card'>
                        <img src='/Images/ExecutiveProfileImages/HarshDewoolkar.jpeg' alt='Executive' className='profile-image' />
                        <div className='card-info'>
                            <h2>Ar. Harsh Dewoolkar</h2>
                            <p>Partner, Gagangiri Group</p>
                        </div>
                    </div>
                </div>
                <div className='right-section'>
                    <p className='profile-info'>
                        Harsh Dewoolkar is a registered Architect under the Council of Architecture and also a Chartered Member of the Royal Institute of British Architecture (RIBA), UK. Bringing a wealth of expertise and innovation to the firm, Harsh plays a key role as a partner at Gagangiri Group. With a prestigious RIBA qualification, Harsh has a solid foundation in both architectural standards and design principles.
                    </p>
                    <p className='profile-info'>
                        As a partner at Gagangiri Group, Harsh is deeply involved in every stage of project development, from conceptualization to execution, ensuring that the firm consistently delivers high-quality results that meet and exceed client requirements. He continues to drive the firm's growth, while maintaining a strong commitment to innovation, sustainability, and excellence in design.
                    </p>
                    <p className='profile-info'>
                        Harsh Dewoolkar’s international experience and forward-thinking approach makes him a valuable asset to Gagangiri Group, ensuring that the firm remains at the forefront of residential redevelopment.
                    </p>
                </div>
            </div>
            <div className='profile-container-2'>
                <div className='left-section-2'>
                    <p className='profile-info'>
                        Mr.Parth Dewoolkar is a Royal Institute of British Architecture (RIBA) certified architect and qualified as a Chartered Surveyor under Royal Institute of Chartered Surveyor (RICS), having a foundation in architectural design and real estate principles. With a blend of international experience, architectural acumen and real estate development knowledge, he aims to make significant contributions to the future growth and success of Gagangiri Group.
                    </p>
                </div>
                <div className='right-section-2'>
                    <div className='card'>
                        <img src='/Images/ExecutiveProfileImages/ParthDewoolkar.jpeg' alt='Executive' className='profile-image' />
                        <div className='card-info'>
                            <h2>Parth Dewoolkar</h2>
                            <p>Partner, Gagangiri Group</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ExecutiveProfile;